import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Button,
  Sheet,
  Typography,
} from "@mui/joy";
import { db } from "../../firebase"; // Firebase import
import { collection, addDoc } from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
} from "firebase/storage"; // Firebase storage
import SupportSystem from "./SupportSystem";
import front1 from "../../assets/IMG-20241124-WA0018.jpg";

const MainSupport = () => {
  const [description, setDescription] = useState("");
  const [selectedFrontImage, setSelectedFrontImage] = useState(null);
  const [selectedBackImage, setSelectedBackImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedModalOption, setSelectedModalOption] = useState("");
  const storage = getStorage();
  // Handle file change (Front Side Image)
  const handleFrontImageChange = (event) => {
    const file = event?.target?.files[0];
    setSelectedFrontImage(file);
  };

  // Handle file change (Back Side Image)
  const handleBackImageChange = (event) => {
    const file = event?.target?.files[0];
    setSelectedBackImage(file);
  };

  // Handle video file change
  const handleVideoChange = (event) => {
    const video = event?.target?.files[0];
    setSelectedVideo(video);
  };

  // Handle category and description change
  const handleSlotChange = (event) => {
    setSelectedModalOption(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  // Upload file to Firebase Storage
  const uploadFile = async (file, folderName) => {
    const fileRef = ref(storage, `${folderName}/${file.name}`);
    const uploadTask = uploadBytesResumable(fileRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        (error) => reject(error),
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Upload images and video (if selected)
      const frontImageURL = selectedFrontImage
        ? await uploadFile(selectedFrontImage, "idProof/front")
        : null;
      const backImageURL = selectedBackImage
        ? await uploadFile(selectedBackImage, "idProof/back")
        : null;
      const videoURL = selectedVideo
        ? await uploadFile(selectedVideo, "videos")
        : null;

      // Add data to Firestore
      await addDoc(collection(db, "supportRequests"), {
        category: selectedModalOption,
        description: description,
        frontImageURL: frontImageURL,
        backImageURL: backImageURL,
        videoURL: videoURL,
        createdAt: new Date(),
      });
      
      // Clear all fields
    // setCategory("");
    setDescription("");
    setSelectedFrontImage(null);
    setSelectedBackImage(null);
    setSelectedVideo(null);
    setSelectedModalOption("");
    } catch (error) {
      console.error("Error storing data: ", error);
    }
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <div className="px-4" style={{ marginTop: "45px" }}>
        <Typography
          level="h2"
          style={{
            color: "#FFFFFF",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
          fontWeight="lg"
        >
          Support System
        </Typography>
      </div>
      <div style={{ padding: 15 }}>
        <Sheet
          variant="soft"
          sx={{
            padding: 3,
            borderRadius: "sm",
            maxWidth: 1500,
            marginTop: 2,
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            backdropFilter: "blur(10px)",
            boxShadow: "none",
          }}
          bgcolor="neutral.softHoverBg"
        >
          {/* Category Selection */}
          <FormControl sx={{ marginTop: 2, minWidth: 200 }}>
            <FormLabel>Categorize :</FormLabel>
            <select
              name="selectedModalOption"
              value={selectedModalOption}
              onChange={handleSlotChange}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                backgroundColor: "#1a2a339c",
                color: "#fff",
              }}
            >
              <option value="">Select an option</option>
              <option value="Income">Income Related</option>
              <option value="Profile">Profile Related</option>
              <option value="Top">Top-Up Related</option>
              <option value="Wallet">Wallet</option>
              <option value="Withdraw">Withdraw</option>
            </select>
          </FormControl>

          {/* Description Input */}
          <FormControl sx={{ marginTop: 2, minWidth: 200 }}>
            <FormLabel>Description:</FormLabel>
            <textarea
              name="description"
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Enter your description here"
              rows="3"
              style={{
                width: "100%",
                backgroundColor: "#1a2a339c",
                padding: "10px",
                borderRadius: "8px",
                color: "#fff",
                fontSize: "1rem",
                border: "none",
              }}
            />
          </FormControl>

          {/* Image Uploads */}
          <FormControl sx={{ marginTop: 2, minWidth: 200 }}>
            <FormLabel>Front Side Image:</FormLabel>
            <input
              type="file"
              onChange={handleFrontImageChange}
              style={{ backgroundColor: "#1a2a339c", color: "white" }}
            />
          </FormControl>

          <FormControl sx={{ marginTop: 2, minWidth: 200 }}>
            <FormLabel>Back Side Image:</FormLabel>
            <input
              type="file"
              onChange={handleBackImageChange}
              style={{ backgroundColor: "#1a2a339c", color: "white" }}
            />
          </FormControl>

          {/* Video Upload */}
          <FormControl sx={{ marginTop: 2, minWidth: 200 }}>
            <FormLabel>Upload a Video:</FormLabel>
            <input
              type="file"
              accept="video/*"
              onChange={handleVideoChange}
              style={{ backgroundColor: "#1a2a339c", color: "white" }}
            />
          </FormControl>

          {/* Submit Button */}
          <Button
            type="submit"
            color="primary"
            // variant="contained"
            sx={{ marginTop: 2 }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <SupportSystem />
        </Sheet>
      </div>
    </div>
  );
};

export default MainSupport;
