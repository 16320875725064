import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";
import Button from "@mui/joy/Button";
import framesxTheme from "../theme";
import { useAuth } from "../context/AuthContext";
import Input from "@mui/joy/Input";
import { FormLabel } from "@mui/joy";
import SupportSystem from "./support/SupportSystem";
import front1 from "../assets/IMG-20241124-WA0018.jpg";


const Settings = () => {
  const { user, updateProfileInfo } = useAuth();
  const [displayName, setDisplayName] = React.useState(user?.displayName || "");
  const [mobileNumber, setMobileNumber] = React.useState(
    user?.mobileNumber || ""
  );
  const [email, setEmail] = React.useState(user?.email || "");
  const [isEditing, setIsEditing] = React.useState(false);

  const handleUpdateProfile = async () => {
    const profileInfo = {
      displayName: displayName,
      mobileNumber: mobileNumber,
    };
    try {
      await updateProfileInfo(profileInfo).then((res) => {
      });

      setIsEditing(false);
    } catch (error) {
      console.error("Error updating profile:", error.message);
    }
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <ThemeProvider disableTransitionOnChange theme={framesxTheme}>
        <CssBaseline />
        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{
            pt: 8,
            pb: 6,
            px: 2,
          }}
        >
          <div className="px-4">
            <Typography level="h2" style={{ color: "#fff", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)" }} fontWeight="lg">
              Profile Information
            </Typography>
          </div>
          {!isEditing ? (
            <div>
              <div
                className="py-2"
                style={{ marginTop: "20px", color: "black" }}
              >
                <FormLabel className="pb-2" style={{ color: "black"}}>
                  Display Name :
                </FormLabel>
                <Input type="text" disabled value={displayName} fullWidth  sx={{backgroundColor: "#1a2a339c"}}  />
              </div>
              <div className="py-2">
                <FormLabel className="pb-2" style={{ color: "black" }}>
                  Email :
                </FormLabel>
                <Input type="text" disabled value={email} fullWidth  sx={{backgroundColor: "#1a2a339c"}}  />
              </div>
              <div className="py-2 mb-3">
                <FormLabel className="pb-2" style={{ color: "black" }}>
                  Mobile Number :
                </FormLabel>
                <Input type="text" disabled value={mobileNumber} fullWidth  sx={{backgroundColor: "#1a2a339c"}}  />
              </div>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                sx={{
                  color: "white",
                  borderColor: "white",
                  // width: "100%",
                  backgroundColor: "#a60000" 
                }}
                onClick={() => setIsEditing(true)}
              >
                Edit Profile
              </Button>
            </div>
          ) : (
            <form>
              <div className="py-2">
                <FormLabel className="pb-2" style={{ color: "black" }}>
                  Display Name :
                </FormLabel>
                <Input
                  fullWidth
                  id="displayName"
                  placeholder="Display Name"
                  variant="outlined"
                  margin="normal"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                  sx={{backgroundColor: "#1a2a339c"}}
                />
              </div>
              <div className="py-2">
                <FormLabel className="pb-2" style={{ color: "black" }}>
                  Email :
                </FormLabel>
                <Input
                  fullWidth
                  id="email"
                  placeholder="Email"
                  variant="outlined"
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{backgroundColor: "#1a2a339c"}}
                />
              </div>
              <div className="py-2 mb-3">
                <FormLabel className="pb-2" style={{ color: "black" }}>
                  Mobile Number :
                </FormLabel>
                <Input
                  fullWidth
                  id="mobileNumber"
                  placeholder="Mobile Number"
                  variant="outlined"
                  margin="normal"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  sx={{backgroundColor: "#1a2a339c"}}
                />
              </div>
              <div
                className="flex justify-between"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  color="danger"
                  sx={{ color: "white", borderColor: "white", backgroundColor: '#595959' }}
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="solid"
                  color="success"
                  onClick={handleUpdateProfile}
                >
                  Update Profile
                </Button>
              </div>
            </form>
          )}
        </Container>
      </ThemeProvider>
      <SupportSystem />
    </div>
  );
};

export default Settings;
