// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Typography,
//   FormControl,
//   FormLabel,
//   Input,
//   Button,
//   Sheet,
// } from "@mui/joy";
// import {
//   Table as MuiTable,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
// } from "@mui/material";
// import { db } from "../firebase"; // Firebase configuration import
// import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
// import { toast } from "react-toastify";
// import ModeIcon from "@mui/icons-material/Mode";
// import Table from "../components/Table";
// import front1 from "../assets/IMG-20241124-WA0018.jpg";

// function OldSettleApproveList() {
//   const [settlements, setSettlements] = useState([]);
//   const [loading, setLoading] = useState(true);
//   console.log("snehan", settlements);

//   useEffect(() => {
//     const fetchSettlements = async () => {
//       try {
//         const querySnapshot = await getDocs(collection(db, "oldSettlement"));
//         const settlementsData = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
//         console.log("tyb", settlementsData);

//         setSettlements(settlementsData);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching data: ", error);
//         toast.error("Error fetching data.");
//         setLoading(false);
//       }
//     };

//     fetchSettlements();
//   }, []);

//   const handleStatusUpdate = async (id, status) => {
//     try {
//       const docRef = doc(db, "oldSettlement", id); // Reference the specific document by ID
//       await updateDoc(docRef, { status }); // Update the `status` field
//       setSettlements((prev) =>
//         prev.map((settlement) =>
//           settlement.id === id ? { ...settlement, status } : settlement
//         )
//       ); // Update the state locally for immediate UI update
//       toast.success(`Settlement status updated to ${status}.`);
//     } catch (error) {
//       console.error(`Error updating status to ${status}:`, error);
//       toast.error(`Failed to update settlement status.`);
//     }
//   };

//   const columns = [
//     { header: "Name", accessorKey: "ClinName" },
//     { header: "old ID", accessorKey: "SdfxId" },
//     { header: "Password", accessorKey: "password" },
//     { header: "New Password", accessorKey: "newPassword" },
//     { header: "Recommended Leader Name", accessorKey: "leaderName" },
//     { header: "Amount", accessorKey: "amount" },
//     { header: "Balance", accessorKey: "balance" },
//     { header: "Approved Amount", accessorKey: "approvedamount" },
//     { header: "Percentage", accessorKey: "percentage" },
//     { header: "Status", accessorKey: "status" },
//     { header: "Dashboard Image", accessorKey: "dashboardImage", isImage: true },
//     { header: "Wallet Image", accessorKey: "walletImage", isImage: true },
//     {
//       header: "Date",
//       accessorKey: "timestamp",
//       cellRenderer: (params) =>
//         new Date(params.value.seconds * 1000).toLocaleString(),
//     },
//   ];

//   return (
//     <div style={{ position: "relative", minHeight: "100vh" }}>
//       {/* Background Image */}
//       <img
//         src={front1}
//         alt="Background"
//         style={{
//           width: "100%",
//           height: "100%",
//           objectFit: "cover",
//           position: "absolute",
//           top: 0,
//           left: 0,
//           zIndex: -1, // Places the image behind the content
//         }}
//       />
//       <Typography
//         level="h2"
//         style={{
//           color: "#FFFFFF",
//           textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)",
//         }}
//         fontWeight="lg"
//         sx={{
//           fontSize: { xs: "1.5rem", md: "2rem" },
//           textAlign: "left",
//           marginTop: 6,
//           marginLeft: 3,
//           textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)",
//         }}
//       >
//         Old Settlements
//       </Typography>
//       <div style={{ overflowX: "auto", maxWidth: "100%" }}>
//         <Table
//           columns={columns}
//           data={settlements.map((settlement) => ({
//             ...settlement,
//             timestamp: new Date(
//               settlement.timestamp.seconds * 1000
//             ).toLocaleString(),
//           }))}
//           getRowProps={() => ({
//             style: {
//               backgroundColor: "rgba(255, 255, 255, 0.2)",
//               borderBottom: "1px solid rgba(255, 255, 255, 0.5)",
//             },
//           })}
//           getCellProps={() => ({
//             style: {
//               backgroundColor: "rgba(255, 255, 255, 0.1)",
//               padding: "8px",
//             },
//           })}
//         />
//       </div>
//     </div>
//   );
// }

// // Table header and cell styling
// const tableHeaderStyle = {
//   padding: "10px",
//   borderBottom: "2px solid #555",
//   textAlign: "left",
//   backgroundColor: "#555",
// };

// const tableCellStyle = {
//   padding: "10px",
//   borderBottom: "1px solid #555",
//   backgroundColor: "#bcbcbc",
//   color: "black",
// };

// export default OldSettleApproveList;

import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Modal } from "@mui/joy";
import { db } from "../firebase"; // Firebase configuration import
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import ModeIcon from "@mui/icons-material/Mode";
import { useNavigate } from "react-router-dom";
import front1 from "../assets/IMG-20241124-WA0018.jpg";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

function OldSettleApproveList() {
  const navigate = useNavigate();
  const [settlements, setSettlements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "oldSettlement"));
        const settlementsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSettlements(settlementsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        toast.error("Error fetching data.");
        setLoading(false);
      }
    };

    fetchSettlements();
  }, []);

  const updateOldSettlementStatus = async (id, newStatus) => {
    try {
      const oldSetelRef = doc(db, 'oldSettlement', id);
      await updateDoc(oldSetelRef, { status: newStatus });

      setSettlements((prevOldSettlement) =>
        prevOldSettlement.map((old) =>
          old.id === id ? { ...old, status: newStatus } : old
        )
      );
      toast.success(`OldSettlement ${id} status updated to ${newStatus}!`);
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status!');
    }
  };

  const handleAccept = async (id) => {
    updateOldSettlementStatus(id, 'Accepted');

  };
  
  const handleReject = async (id) => {
    updateOldSettlementStatus(id, 'Rejected');

  };

  

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />

      {/* Page Content */}
      <Box
        sx={{
          padding: 2,
          margin: "0 auto",
          maxWidth: "1200px",
          color: "#fff",
          textAlign: "left",
        }}
      >
        <Typography
          level="h2"
          sx={{
            fontSize: { xs: "1.5rem", md: "2rem" },
            fontWeight: "bold",
            marginBottom: 3,
            color: "#fff",
          }}
        >
          Old Settlements
        </Typography>
        <Button
          variant="solid"
          onClick={() => navigate("/oldsettlement")}
          sx={{ marginBottom: 2, backgroundColor: "#6b6c6d" }}
        >
          <AddCircleOutlineIcon />
        </Button>
        {/* Responsive Table */}
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              color: "#FFFFFF",
              backgroundColor: "#333",
            }}
          >
            <thead>
              <tr>
                {[
                  "SL.No",
                  "Date",
                  "Name",
                  "Old ID",
                  "Recommended Leader Name",
                  "Recommended Leader ID",
                  "Amount",
                  "Balance",
                  "Approved Amount",
                  "Percentage",
                  "Dashboard Image",
                  "Wallet Image",
                  "statuse",
                  "Action",
                ].map((header) => (
                  <th key={header} style={tableHeaderStyle}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {settlements.map((settlement,i) => (
                <tr key={settlement.id}>
                <td style={tableCellStyle}>{i + 1}</td>
                <td style={tableCellStyle}>
                  {new Intl.DateTimeFormat('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  }).format(new Date(settlement.timestamp.seconds * 1000))}
                </td>
                  <td style={tableCellStyle}>{settlement.ClinName}</td>
                  <td style={tableCellStyle}>{settlement.SdfxId}</td>
                  <td style={tableCellStyle}>{settlement.leaderName}</td>
                  <td style={tableCellStyle}>{settlement.leaderId}</td>
                  <td style={tableCellStyle}>{settlement.amount}</td>
                  <td style={tableCellStyle}>{settlement.balance}</td>
                  <td style={tableCellStyle}>{settlement.approvedamount}</td>
                  <td style={tableCellStyle}>{settlement.percentage}</td>
                  <td style={tableCellStyle}>
                    <img
                      src={settlement.dashboardImage}
                      alt="Dashboard"
                      onClick={() =>
                        handleImageClick(settlement.dashboardImage)
                      }
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                  <td style={tableCellStyle}>
                    <img
                      src={settlement.walletImage}
                      alt="Wallet"
                      onClick={() => handleImageClick(settlement.walletImage)}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                  <td style={tableCellStyle}>{settlement.status}</td>
                  <td style={tableCellStyle}>
                    <div style={{display:'flex'}}>
                    <Button
                      variant="soft"
                      color="success"
                      size="sm"
                      onClick={() => handleAccept(settlement.id)}
                      sx={{ marginRight: "8px" }}
                    >
                      Accept
                    </Button>
                    <Button
                      variant="soft"
                      color="danger"
                      size="sm"
                      onClick={() => handleReject(settlement.id)}
                    >
                      Reject
                    </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Box>

      {/* Modal for Image Preview */}
      <Modal open={!!selectedImage} onClose={closeModal}>
        <Box
          sx={{
            padding: 2,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "90vw",
          }}
        >
          <img
            src={selectedImage}
            alt="Enlarged"
            style={{
              maxWidth: "90%",
              maxHeight: "30vh",
              borderRadius: "8px",
            }}
          />
          <Button onClick={closeModal} color="danger" sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

// Table header and cell styling
const tableHeaderStyle = {
  padding: "8px",
  borderBottom: "2px solid #555",
  textAlign: "left",
  backgroundColor: "#444",
};

const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid #555",
  textAlign: "center",
};

export default OldSettleApproveList;
