import React, { useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Sheet,
  Button,
} from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import SendIcon from '@mui/icons-material/Send';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase'; // Import your Firestore database
import framesxTheme from '../../theme';
import SupportSystem from '../support/SupportSystem';
import { toast } from 'react-toastify';
import front1 from "../../assets/IMG-20241124-WA0018.jpg";


const Message = () => {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  console.log('message', message, title);

  const handleSend = async () => {
    try {
      await addDoc(collection(db, 'messages'), {
        title,
        message,
        createdAt: Timestamp.now(),
      });
      toast.success('Message sent successfully');
      setTitle('');
      setMessage('');
    } catch (e) {
      console.error('Error adding document: ', e);
      alert('Failed to send message');
    }
  };

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Box
        sx={{
          padding: 2,
          minHeight: "100vh",
          position: "relative", // Required for positioning children
          overflow: "hidden", // Ensures content doesn't spill outside
        }}
      >
        <img
          src={front1}
          alt="Background"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1, // Places the image behind the content
          }}
        />
        <Typography level="h2" style={{ color: '#FFFFFF', marginTop: "24px", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", }} fontWeight="lg">
          Message
        </Typography>
        <Typography level="body-sm" style={{ color: '#FFFFFF', textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", fontSize: "18px", }} mb={2}>
          Send Your Message
        </Typography>
        <Sheet
          variant="soft"
          sx={{
            padding: 3,
            borderRadius: 'sm',
            maxWidth: 1500,
            marginTop: 2,
            backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white
            backdropFilter: "blur(10px)", // Optional: Adds a blur effect for the content behind
            boxShadow: "none",
          }}
          bgcolor="neutral.softHoverBg"
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel style={{ color: 'black' }}>Title</FormLabel>
              <Input
                placeholder="Enter Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                type="textbox"
                sx={{backgroundColor: "#1a2a339c", color: 'white'}}
              />
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel style={{ color: 'black' }}>Message</FormLabel>
              <Input
                placeholder="Enter Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                type="textarea"
                sx={{backgroundColor: "#1a2a339c", color: 'white'}}
              />
            </FormControl>
            <Button
              variant="solid"
              color="primary"
              sx={{
                alignSelf: 'center',
                marginTop: 2,
                display: 'flex',
                gap: '4px',
              }}
              onClick={handleSend}
            >
              Send
              <SendIcon />
            </Button>
          </Box>
        </Sheet>
      </Box>
      <SupportSystem />
    </CssVarsProvider>
  );
};

export default Message;
