import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
} from "@mui/joy";
import { db } from "../../firebase"; // Firebase configuration import
import { collection, getDocs, query, where } from "firebase/firestore";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";
import front1 from "../../assets/IMG-20241124-WA0018.jpg";

function InvestmentReport() {
  const { user } = useAuth();
  const [settlements, setSettlements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        const currentUserUid = user.uid; // Replace this with the actual UID of the current user (e.g., fetched from auth)

        const q = query(collection(db, "liveAccountRequests"));

        const querySnapshot = await getDocs(q);
        const settlementsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSettlements(settlementsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        toast.error("Error fetching data.");
        setLoading(false);
      }
    };

    fetchSettlements();
  }, []);

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <Typography
        level="h2"
        style={{ color: "#FFFFFF" }}
        fontWeight="lg"
        sx={{
          fontSize: { xs: "2rem", md: "2.5rem" },
          textAlign: "left",
          marginLeft: 3,
          marginTop: 5,
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
        }}
      >
        Investment Report
      </Typography>
      <div style={{ overflowX: "auto", maxWidth: "100%", padding: "20px" }}>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            color: "#FFFFFF", // White text
            backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent white background
            marginTop: "1rem",
            border: "1px solid rgba(255, 255, 255, 0.2)", // Optional border for visibility
          }}
        >
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Date & Time</th>
              <th style={tableHeaderStyle}>Payment Mode</th>
              <th style={tableHeaderStyle}>USDT</th>
            </tr>
          </thead>
          <tbody>
            {settlements
              .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date in descending order
              .map((item) => (
                <tr key={item.id}>
                  <td style={tableCellStyle}>
                    {item.createdAt
                      ? new Date(item.createdAt).toLocaleString() // Handle ISO string
                      : "No timestamp available"}
                  </td>
                  <td style={tableCellStyle}>{item.paymentModeId}</td>
                  <td style={tableCellStyle}>{item.investmentAmount}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// Table header and cell styling
const tableHeaderStyle = {
  padding: "10px",
  borderBottom: "2px solid rgba(255, 255, 255, 0.3)", // Semi-transparent border
  textAlign: "center",
  backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent white background for headers
  color: "black", // White text
};

const tableCellStyle = {
  padding: "10px",
  borderBottom: "1px solid rgba(255, 255, 255, 0.2)", // Semi-transparent border
  textAlign: "center",
  backgroundColor: "#1a2a339c", // Transparent white background
  color: "#FFFFFF", // White text
};

export default InvestmentReport;
