import React, { useEffect, useState } from "react";
import { Box } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { useAuth } from "../context/AuthContext";
import SupportSystem from "./support/SupportSystem";
import front1 from "../assets/IMG-20241124-WA0018.jpg";

const Home = () => {
  const { user } = useAuth();
  const [isVerified, setIsVerified] = useState(user.emailVerified);

  const userId = user?.uid;
  const referralLink = `https://briarv.com/signup?ref=${userId}`;

  useEffect(() => {
    const checkEmailVerification = async () => {
      if (!isVerified) {
        await user.reload(); // Reload the user to get the latest email verification status
        setIsVerified(user.emailVerified);
      }
    };

    // Check the email verification status every 5 seconds (adjust as needed)
    const intervalId = setInterval(checkEmailVerification, 5000);
    return () => clearInterval(intervalId); // Clear the interval on component unmount
  }, [isVerified, user]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Background Image */}
        <img
          src={front1}
          alt="Background"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1, // Places the image behind the content
          }}
        />

        {/* Content */}
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            padding: "20px",
            position: "relative",
          }}
        >
          {!isVerified ? (
            <Typography
              level="h2"
              sx={{
                color: "#FFFFFF",
                fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                fontWeight: "bold",
                mb: 2,
              }}
            >
              Please verify your email to access Briarwood Ventures!
            </Typography>
          ) : (
            <>
              <Typography
                level="h2"
                sx={{
                  color: "#FFFFFF",
                  fontSize: { xs: "2rem", sm: "3rem", md: "4rem" }, // Increased font sizes
                  fontWeight: "bold",
                  mb: 2,
                  textShadow: "2px 2px 8px rgba(0, 0, 0, 0.8)", // Shadow effect
                  padding: "10px 20px", // Padding inside the border
                  position: "relative", // Allows adjustment via top
                  top: "-60px",
                }}
              >
                Welcome to the Briarwood Ventures!
              </Typography>
              {/* <Typography
                level="h2"
                sx={{
                  color: "#FFFFFF",
                  fontSize: { xs: "1rem", sm: "1.5rem" },
                  fontWeight: "bold",
                  mb: 2,
                  padding: "10px 20px",
                  border: "2px solid rgba(255, 255, 255, 0.8)", // Border with slight transparency
                  borderRadius: "8px", // Rounded corners
                  backgroundColor: "rgba(64, 71, 79, 0.7)", // Semi-transparent background
                }}
              >
                Referral Link: {referralLink}
              </Typography> */}
            </>
          )}
        </Box>
      </Box>
      <SupportSystem />
    </>
  );
};

export default Home;
