import React, { createContext, useContext, useState } from 'react';

const GenealogyTreeContext = createContext();

export const GenealogyTreeProvider = ({ children }) => {
  const [treeData, setTreeData] = useState(null);
  return (
    <GenealogyTreeContext.Provider value={{ treeData, setTreeData }}>
      {children}
    </GenealogyTreeContext.Provider>
  );
};

export const useGenealogyTree = () => {
  return useContext(GenealogyTreeContext);
};
