import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../context/AuthContext';
import Tree from 'react-d3-tree';
import { useGenealogyTree } from '../../context/TreeContext';

const GenealogyTree = () => {
  const { user } = useAuth();
  const { treeData, setTreeData } = useGenealogyTree();
  const [loading, setLoading] = useState(true);
  const [curentUserData, setCurentUserData] = useState(null);
  const [treeContainer, setTreeContainer] = useState(null); // To get the div dimensions
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const tenantsCollection = collection(db, 'members');

  useEffect(() => {
    const fetchData = async () => {
      if (tenantsCollection) {
        const data = await fetchCurMembers(tenantsCollection);
        if (data) {
          setCurentUserData(data[0]);
        }
      }
    };
    fetchData();
  }, [user]);

  async function fetchCurMembers(ref) {
    const snapshot = query(ref, where('email', '==', user?.email));
    const fetchData = await getDocs(snapshot);
    const results = [];

    fetchData.forEach((doc) => {
      results.push({ id: doc.id, ...doc.data() });
    });

    return results.length > 0 ? results : null;
  }

  useEffect(() => {
    const loadTree = async () => {
      if (!curentUserData || !curentUserData.BVID) return;

      try {
        const members = await fetchMembers();

        const currentUser = members.find(
          (member) => member.BVID === curentUserData.BVID
        );

        if (!currentUser) {
          throw new Error('Current user data not found');
        }

        const tree = {
          name: currentUser.name,
          attributes: { BVID: currentUser.BVID },
          children: buildTree(members, currentUser.BVID),
        };

        // Store tree data in the context
        setTreeData(tree);
      } catch (error) {
        console.error('Error fetching tree data:', error);
      } finally {
        setLoading(false);
      }
    };
    loadTree();
  }, [curentUserData, setTreeData]);

  const fetchMembers = async () => {
    const querySnapshot = await getDocs(collection(db, 'members'));
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  };

  const buildTree = (members, parentId) => {
    const children = members
      .filter((member) => member.parentId === parentId)
      .map((child) => ({
        name: child.name,
        attributes: { BVID: child.BVID },
        children: buildTree(members, child.BVID) || null,
      }));
    return children.length > 0 ? children : null;
  };

  useEffect(() => {
    if (treeContainer) {
      const { width, height } = treeContainer.getBoundingClientRect();
      setTranslate({ x: width / 2, y: height / 8 }); // Adjust initial centering
    }
  }, [treeContainer]);

  return (
    <div
      style={{
        marginTop: '5%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
      ref={setTreeContainer}
    >
      {loading ? (
        <p>Loading tree...</p>
      ) : treeData ? (
        <div id="treeWrapper" style={{ width: '100%', height: '100%' }}>
          <Tree
            data={treeData}
            orientation="vertical"
            pathFunc="step"
            nodeSize={{ x: 300, y: 200 }}
            translate={translate} // Dynamically center the tree
          />
        </div>
      ) : (
        <p>No members found.</p>
      )}
    </div>
  );
};

export default GenealogyTree;
