import React from 'react';
import Sidebar from '../components/Sidebar';
import { Box, CssVarsProvider } from '@mui/joy';
import { CssBaseline } from '@mui/material';
import Header from '../components/Header';
import framesxTheme from '../theme';
function Layout({ children }) {
  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <CssBaseline />
      <Box
        component="main"
        className="MainContent"
        // className="bg-slate-400"
        sx={{ display: 'flex' }}
      >
        <Sidebar />
        <Header />
        <Box
          sx={{
            // pt: { xs: 'calc(12px + var(--Header-height))', md: 3 },
            mt: { xs: '8%', md: '5%', lg: '3%' },
            // pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            // minWidth: '100%',
            // height: '100dvh',
            gap: 1,
            // overflow: "auto",
            // backgroundImage: "url('https://plus.unsplash.com/premium_photo-1661609098718-3408828713ba?q=80&w=2081&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
            // backgroundSize: "cover",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
          }}
        >
          {children}
        </Box>
      </Box>
    </CssVarsProvider>
  );
}

export default Layout;
