import * as React from "react";
import GlobalStyles from "@mui/joy/GlobalStyles";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import FluorescentIcon from "@mui/icons-material/Fluorescent";
import LinearProgress from "@mui/joy/LinearProgress";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import SupportRoundedIcon from "@mui/icons-material/SupportRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import BrightnessAutoRoundedIcon from "@mui/icons-material/BrightnessAutoRounded";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import WalletIcon from "@mui/icons-material/Wallet";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ApprovalIcon from "@mui/icons-material/Approval";
import MessageIcon from "@mui/icons-material/Message";
import ColorSchemeToggle from "./ColorSchemeToggle";
import { closeSidebar } from "../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useEffect } from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

function Toggler({ defaultExpanded = false, renderToggle, children }) {
  const [open, setOpen] = React.useState(defaultExpanded);

  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: open ? "1fr" : "0fr",
          transition: "0.2s ease",
          "& > *": {
            overflow: "hidden",
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const [cretenuser, setCurentUser] = React.useState([]);
  const userEmail = user.email;
  const userRole = cretenuser.role;
  const [curentUserData, setCurentUserData] = useState([]);
  const tenantsCollection = collection(db, "members");

  const checkEmailInMembers = async () => {
    try {
      // Step 1: Create a query to check if the email exists in the members collection
      const membersRef = collection(db, "Tenants");
      const q = query(membersRef, where("email", "==", userEmail));
      const querySnapshot = await getDocs(q);

      // Step 2: Check if any documents match the query
      if (!querySnapshot.empty) {
        const memberData = querySnapshot.docs[0].data(); // Assuming one matching document
        setCurentUser(memberData);
        // Optionally store or display the member data
        return memberData;
      } else {
        console.log("No matching member found.");
        return null;
      }
    } catch (error) {
      console.error("Error checking member email:", error.message);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (tenantsCollection) {
        const data = await fetchMembers(tenantsCollection);
        setCurentUserData(data[0]);
      }
    };
    fetchData(); // Call the async function
  }, []);

  async function fetchMembers(ref) {
    const snapshot = query(ref, where("email", "==", user?.email));
    const fetchData = await getDocs(snapshot);
    const results = [];

    fetchData.forEach((doc) => {
      const abc = { id: doc.id, ...doc.data() };
      results.push(abc);
    });

    if (results.length > 0) {
      return results;
    } else {
      return null;
    }
  }

  React.useEffect(() => {
    checkEmailInMembers();
  }, []);

  const handleLogout = async () => {
    await logout().then(() => {
      navigate("/");
    });
  };

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: "fixed", md: "flex", backgroundColor: "#414e55" },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          md: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          lg: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 10000,
        height: "100dvh",
        width: "var(--Sidebar-width)",
        top: 0,
        p: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "240px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "260px",
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: "var(--SideNavigation-slideIn)", // Control the opacity of the Box
          backgroundColor: "rgba(0, 0, 0, 0.3)", // This sets a 30% transparency for the overlay
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            // lg: "translateX(-100%)",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <IconButton variant="soft" color="primary" size="sm">
          <CurrencyBitcoinIcon />
        </IconButton>
        <Typography level="title-sm" sx={{ color: "white" }}>
          {/* {user.email} */}
          {curentUserData?.name || user.email}
        </Typography>
        <IconButton
          size="sm"
          onClick={() => closeSidebar()} // Close sidebar when clicked
          sx={{
            backgroundColor: "#d40000",
            color: "#FB8B24",
            "&:hover": { backgroundColor: "red" },
            marginLeft: 3,
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
        {/* <ColorSchemeToggle sx={{ ml: "auto" }} /> */}
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
            "&:hover": {
              backgroundColor: "#ce0000", // Hover color for list item buttons
            },
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            "--List-nestedInsetStart": "30px",
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
          }}
        >
          <ListItem>
            <ListItemButton
              selected={location.pathname === "/home"}
              role="menuitem"
              component="a"
              onClick={() => {
                navigate("/home");
                closeSidebar();
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#ce0000", // Red hover color
                },
                ...(location.pathname === "/home" && {
                  backgroundColor: "#ce0000", // Red color when selected
                }),
              }}
            >
              <HomeRoundedIcon sx={{ color: "white" }} />
              <ListItemContent>
                <Typography
                  level="title-sm"
                  sx={{
                    color: "white",
                    "&:hover": {
                      color: "black",
                    },
                  }}
                >
                  Home
                </Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton
                  selected={false}
                  href="/dashboard"
                  onClick={() => setOpen(!open)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "&:hover *": {
                      color: "#ce0000",
                    },
                  }}
                >
                  <AssignmentRoundedIcon sx={{ color: "white" }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: "white",
                        "&:hover": {
                          color: "#ce0000",
                        },
                      }}
                    >
                      Dashboard
                    </Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{
                      color: "white",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem
                  onClick={() => {
                    navigate("/dashboard");
                    closeSidebar();
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton
                    selected={false}
                    sx={{
                      color: "white",
                      "&:hover *": {
                        color: "red",
                      },
                    }}
                  >
                    Dashboard
                  </ListItemButton>
                </ListItem>
                {/* <ListItem>
                  <ListItemButton selected={false} sx={{ color: 'white' }}>
                    Business Summary
                  </ListItemButton>
                </ListItem> */}
              </List>
            </Toggler>
          </ListItem>
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton
                  selected={false}
                  href="/myaccounts"
                  onClick={() => setOpen(!open)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "&:hover *": {
                      color: "#ce0000",
                    },
                  }}
                >
                  <AccountCircleIcon sx={{ color: "white" }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: "white",
                        "&:hover": {
                          color: "red",
                        },
                      }}
                    >
                      My Accounts
                    </Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{
                      transform: open ? "rotate(180deg)" : "none",
                      color: "white",
                    }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem
                  onClick={() => {
                    navigate("/mycripto");
                    closeSidebar();
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: "white" }}>
                    My Crypto address
                  </ListItemButton>
                </ListItem>
                <ListItem
                  onClick={() => {
                    navigate("/myprofile");
                    closeSidebar();
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: "white" }}>
                    My Profile
                  </ListItemButton>
                </ListItem>
                {/* <ListItem>
                  <ListItemButton selected={false}>My Bank</ListItemButton>
                </ListItem> */}
                <ListItem
                  onClick={() => {
                    navigate("/password");
                    closeSidebar();
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: "white" }}>
                    My Password
                  </ListItemButton>
                </ListItem>
                <ListItem
                  onClick={() => {
                    navigate("/kyc");
                    closeSidebar();
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: "white" }}>
                    KYC Permission
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>

          {/* <ListItem>
            <ListItemButton
              selected={false}
              role="menuitem"
              component="a"
              href="/orders"
            >
              <ShoppingCartRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Orders</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem> */}
          {/* <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton selected={false} onClick={() => setOpen(!open)}>
                  <AssignmentRoundedIcon />
                  <ListItemContent>
                    <Typography level="title-sm">Equity Upload</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? "rotate(180deg)" : "none" }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton selected={false}>Add Equity</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton selected={false}>View Equity</ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem> */}
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton
                  selected={false}
                  onClick={() => setOpen(!open)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "&:hover *": {
                      color: "#ce0000",
                    },
                  }}
                >
                  <SupervisorAccountIcon sx={{ color: "white" }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: "white",
                        "&:hover": {
                          color: "black",
                        },
                      }}
                    >
                      Member
                    </Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{
                      transform: open ? "rotate(180deg)" : "none",
                      color: "white",
                    }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem
                  onClick={() => {
                    navigate("/addmember");
                    closeSidebar();
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: "white" }}>
                    Add Member
                  </ListItemButton>
                </ListItem>
                {/* <ListItem>
                  <ListItemButton
                    onClick={() => {
                      navigate("/addmemberothercountry");
                    }}
                    selected={false}
                  >
                    Add Member (Other Country)
                  </ListItemButton>
                </ListItem> */}
                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      navigate("/memberlist");
                      closeSidebar();
                    }}
                    selected={false}
                    sx={{ color: "white" }}
                  >
                    Member List
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      navigate("/directmemberlist");
                      closeSidebar();
                    }}
                    selected={false}
                    sx={{ color: "white" }}
                  >
                    Direct Member List
                  </ListItemButton>
                </ListItem>
                {/* <ListItem>
                  <ListItemButton
                    onClick={() => {
                      navigate("/levelview");
                    }}
                    selected={false}
                  >
                    Level View
                  </ListItemButton>
                </ListItem> */}
                {/* <ListItem>
                  <ListItemButton selected={false}>Tree View</ListItemButton>
                </ListItem> */}
                {/* {userRole === 'admin' && ( */}
                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      navigate("/genealogytree");
                      closeSidebar();
                    }}
                    selected={false}
                    sx={{ color: "white" }}
                  >
                    Genealogy Tree
                  </ListItemButton>
                </ListItem>
                {/* )} */}
              </List>
            </Toggler>
          </ListItem>
          {/* <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton selected={false} onClick={() => setOpen(!open)}>
                  <AssignmentRoundedIcon />
                  <ListItemContent>
                    <Typography level="title-sm">Refund Entry</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? "rotate(180deg)" : "none" }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton selected={false}>
                    New Refund Entry
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton selected={false}>
                    View Refund entry
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem> */}
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton
                  selected={false}
                  onClick={() => setOpen(!open)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "&:hover *": {
                      color: "#ce0000",
                    },
                  }}
                >
                  <AssignmentRoundedIcon sx={{ color: "white" }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: "white",
                        "&:hover": {
                          color: "black",
                        },
                      }}
                    >
                      Withdrawal
                    </Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{
                      transform: open ? "rotate(180deg)" : "none",
                      color: "white",
                    }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem
                  onClick={() => {
                    navigate("/trcwithdrawal");
                    closeSidebar();
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: "white" }}>
                    TRC20 Withdrawal
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton
                  selected={false}
                  onClick={() => setOpen(!open)}
                  sx={{
                    color: "white",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "&:hover *": {
                      color: "#ce0000",
                    },
                  }}
                >
                  <AssignmentRoundedIcon sx={{ color: "white" }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: "white",
                        "&:hover": {
                          color: "black",
                        },
                      }}
                    >
                      Principal Withdrawal
                    </Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{
                      transform: open ? "rotate(180deg)" : "none",
                      color: "white",
                    }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem
                  onClick={() => {
                    // navigate("/trcdeposit");
                    closeSidebar();
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: "white" }}>
                    Principal Withdrawal
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton
                  selected={false}
                  onClick={() => setOpen(!open)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "&:hover *": {
                      color: "#ce0000",
                    },
                  }}
                >
                  <AssuredWorkloadIcon sx={{ color: "white" }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: "white",
                        "&:hover": {
                          color: "black",
                        },
                      }}
                    >
                      Deposit
                    </Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{
                      transform: open ? "rotate(180deg)" : "none",
                      color: "white",
                    }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem
                  onClick={() => {
                    navigate("/trcdeposit");
                    closeSidebar();
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: "white" }}>
                    TRC20 Deposit
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>
          <ListItem
            onClick={() => {
              navigate("/mywallet");
              closeSidebar();
            }}
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
              "&:hover *": {
                color: "#ce0000",
              },
            }}
          >
            <ListItemButton selected={false} role="menuitem" component="a">
              <WalletIcon sx={{ color: "white" }} />
              <ListItemContent>
                <Typography
                  level="title-sm"
                  sx={{
                    color: "white",
                    "&:hover": {
                      color: "red",
                    },
                  }}
                >
                  My Wallet
                </Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          {userRole === "admin" && (
            <>
              <ListItem
                onClick={() => {
                  navigate("/live-balance-request-list");
                  closeSidebar();
                }}
              >
                <ListItemButton
                  selected={false}
                  role="menuitem"
                  component="a"
                  // href="/live-balance-request"
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "&:hover *": {
                      color: "#ce0000",
                    },
                  }}
                >
                  <AccountBalanceWalletIcon style={{ color: "#FFFFFF" }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: "white",
                        "&:hover": {
                          color: "black",
                        },
                      }}
                    >
                      Account Balance Request List
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem
                onClick={() => {
                  navigate("/oldsettlementview");
                  closeSidebar();
                }}
              >
                <ListItemButton
                  selected={false}
                  role="menuitem"
                  component="a"
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "&:hover *": {
                      color: "#ce0000",
                    },
                  }}
                >
                  <AccountBalanceIcon sx={{ color: "white" }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: "white",
                        "&:hover": {
                          color: "black",
                        },
                      }}
                    >
                      Old Settlememt
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem
                onClick={() => {
                  navigate("/Approval-List-Of-Old-Settlememt");
                  closeSidebar();
                }}
              >
                <ListItemButton
                  selected={false}
                  role="menuitem"
                  component="a"
                  // href="/live-balance-request"
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "&:hover *": {
                      color: "#ce0000",
                    },
                  }}
                >
                  <ApprovalIcon style={{ color: "#FFFFFF" }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: "white",
                        "&:hover": {
                          color: "black",
                        },
                      }}
                    >
                      Approval List Of Old Settlememt
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>

              <ListItem nested>
                <Toggler
                  renderToggle={({ open, setOpen }) => (
                    <ListItemButton
                      selected={false}
                      onClick={() => setOpen(!open)}
                      sx={{
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                        "&:hover *": {
                          color: "#ce0000",
                        },
                      }}
                    >
                      <AssessmentIcon sx={{ color: "white" }} />
                      <ListItemContent>
                        <Typography
                          level="title-sm"
                          sx={{
                            color: "white",
                            "&:hover": {
                              color: "black",
                            },
                          }}
                        >
                          Report
                        </Typography>
                      </ListItemContent>
                      <KeyboardArrowDownIcon
                        sx={{
                          transform: open ? "rotate(180deg)" : "none",
                          color: "white",
                        }}
                      />
                    </ListItemButton>
                  )}
                >
                  <List sx={{ gap: 0.5 }}>
                    <ListItem
                      onClick={() => {
                        navigate("/investmentreport");
                        closeSidebar();
                      }}
                      sx={{ mt: 0.5 }}
                    >
                      <ListItemButton selected={false} sx={{ color: "white" }}>
                        Investment Report
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      onClick={() => {
                        navigate("/withdrawalreport");
                        closeSidebar();
                      }}
                      sx={{ mt: 0.5 }}
                    >
                      <ListItemButton selected={false} sx={{ color: "white" }}>
                        Withdrawal Report
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      onClick={() => {
                        navigate("/oldsettlementreport");
                        closeSidebar();
                      }}
                      sx={{ mt: 0.5 }}
                    >
                      <ListItemButton selected={false} sx={{ color: "white" }}>
                        Old Settlememt Report
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Toggler>
              </ListItem>
              <ListItem
                onClick={() => {
                  navigate("/investmentdistributionview");
                  closeSidebar();
                }}
              >
                <ListItemButton
                  selected={false}
                  role="menuitem"
                  component="a"
                  // href="/live-balance-request"
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "&:hover *": {
                      color: "#ce0000",
                    },
                  }}
                >
                  <FluorescentIcon style={{ color: "#FFFFFF" }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: "white",
                        "&:hover": {
                          color: "black",
                        },
                      }}
                    >
                      Investment Distribution
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem
                onClick={() => {
                  navigate("/message");
                  closeSidebar();
                }}
              >
                <ListItemButton
                  selected={false}
                  role="menuitem"
                  component="a"
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "&:hover *": {
                      color: "#ce0000",
                    },
                  }}
                  // href="/live-balance-request"
                >
                  <MessageIcon style={{ color: "#FFFFFF" }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: "white",
                        "&:hover": {
                          color: "black",
                        },
                      }}
                    >
                      Send Message
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </>
          )}
          {userRole === "leader" && (
            <>
              <ListItem
                onClick={() => {
                  navigate("/oldsettlementview");
                  closeSidebar();
                }}
              >
                <ListItemButton
                  selected={false}
                  role="menuitem"
                  component="a"
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "&:hover *": {
                      color: "#ce0000",
                    },
                  }}
                >
                  <AccountBalanceIcon sx={{ color: "white" }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: "white",
                        "&:hover": {
                          color: "black",
                        },
                      }}
                    >
                      Old Settlememt
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </>
          )}
        </List>
        <List
          size="sm"
          sx={{
            mt: "auto",
            flexGrow: 0,
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
            "--List-gap": "8px",
            mb: 2,
          }}
        >
          <ListItem
            onClick={() => {
              navigate("/support");
              closeSidebar();
            }}
            sx={{ mt: 0.5, color: "white" }}
          >
            <ListItemButton
              selected={false}
              sx={{
                color: "white",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&:hover *": {
                  color: "#ce0000",
                },
              }}
            >
              <SupportRoundedIcon sx={{ color: "white" }} />
              Support
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/settings");
                closeSidebar();
              }}
              selected={false}
              sx={{
                color: "white",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&:hover *": {
                  color: "#ce0000",
                },
              }}
            >
              <SettingsRoundedIcon
                sx={{
                  color: "white",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  "&:hover *": {
                    color: "#ce0000",
                  },
                }}
              />
              Settings
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#ce0000",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ minWidth: 0, color: "white" }}>
          <Typography
            onClick={handleLogout}
            level="title-sm"
            sx={{ color: "white" }}
          >
            Log-out
          </Typography>
        </Box>
        <IconButton
          onClick={handleLogout}
          size="sm"
          variant="plain"
          color="neutral"
        >
          <LogoutRoundedIcon sx={{ color: "white" }} />
        </IconButton>
      </Box>
    </Sheet>
  );
}
