import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
} from "@mui/joy";
import { db } from "../../firebase"; // Firebase configuration import
import { collection, getDocs, query, where } from "firebase/firestore";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";
import front1 from "../../assets/IMG-20241124-WA0018.jpg";

function WithdrawalReport() {
  const { user } = useAuth();
  const [settlements, setSettlements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        const currentUserUid = user.uid; // Replace this with the actual UID of the current user (e.g., fetched from auth)

        const q = query(
          collection(db, "liveAccountRequests"),
          where("createdBy", "==", currentUserUid),
          where("status", "==", "Accepted")
        );

        const querySnapshot = await getDocs(q);
        const settlementsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSettlements(settlementsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        toast.error("Error fetching data.");
        setLoading(false);
      }
    };

    fetchSettlements();
  }, []);

  const demoData = [
    // {
    //   id: "1",
    //   date: "2024-11-20",
    //   remarks: "Investment",
    //   debit: "800",
    //   credit: "",
    //   balance: "4000",
    // },
    // {
    //   id: "2",
    //   date: "2024-11-19",
    //   remarks: "Settlement",
    //   debit: "",
    //   credit: "1500",
    //   balance: "4800",
    // },
    // {
    //   id: "3",
    //   date: "2024-11-18",
    //   remarks: "Withdrawal",
    //   debit: "500",
    //   credit: "",
    //   balance: "3300",
    // },
    // {
    //   id: "4",
    //   date: "2024-11-17",
    //   remarks: "Investment",
    //   debit: "1200",
    //   credit: "",
    //   balance: "3800",
    // },
    // {
    //   id: "5",
    //   date: "2024-11-16",
    //   remarks: "Settlement",
    //   debit: "",
    //   credit: "2000",
    //   balance: "5000",
    // },
    // {
    //   id: "6",
    //   date: "2024-11-15",
    //   remarks: "Investment",
    //   debit: "1000",
    //   credit: "",
    //   balance: "3000",
    // },
  ];

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <Typography
        level="h2"
        style={{ color: "#FFFFFF" }}
        fontWeight="lg"
        sx={{
          fontSize: { xs: "1.5rem", md: "2rem" },
          textAlign: "left",
          marginLeft: 3,
          marginTop: 5,
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
        }}
      >
        Withdrawal Report
      </Typography>
      <div style={{ overflowX: "auto", maxWidth: "100%", padding: "20px" }}>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            color: "#FFFFFF", // White text
            backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent white background
            marginTop: "1rem",
            border: "1px solid rgba(255, 255, 255, 0.2)", // Optional border for visibility
          }}
        >
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Date</th>
              <th style={tableHeaderStyle}>Remarks</th>
              <th style={tableHeaderStyle}>Debit</th>
              <th style={tableHeaderStyle}>Credit</th>
              <th style={tableHeaderStyle}>Balance</th>
            </tr>
          </thead>
          {/* <tbody>
            {settlements.map((settlement) => (
              <tr key={settlement.id}>
                <td style={tableCellStyle}>
                  {new Date(
                    settlement.timestamp.seconds * 1000
                  ).toLocaleString()}
                </td>
                <td style={tableCellStyle}>{settlement.id}</td>
                <td style={tableCellStyle}>{settlement.password}</td>
                <td style={tableCellStyle}>{settlement.amount}</td>
                <td style={tableCellStyle}>{settlement.balance}</td>
              </tr>
            ))}
          </tbody> */}
          <tbody>
            {demoData
              .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort descending by date
              .map((item) => (
                <tr key={item.id}>
                  <td style={tableCellStyle}>{item.date}</td>
                  <td style={tableCellStyle}>{item.remarks}</td>
                  <td style={tableCellStyle}>{item.debit || "-"}</td>
                  <td style={tableCellStyle}>{item.credit || "-"}</td>
                  <td style={tableCellStyle}>{item.balance}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// Table header and cell styling
const tableHeaderStyle = {
  padding: "10px",
  borderBottom: "2px solid rgba(255, 255, 255, 0.3)", // Semi-transparent border
  textAlign: "center",
  backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent white background for headers
  color: "black", // White text
};

const tableCellStyle = {
  padding: "10px",
  borderBottom: "1px solid rgba(255, 255, 255, 0.2)", // Semi-transparent border
  textAlign: "center",
  backgroundColor: "#1a2a339c", // Transparent white background
  color: "#FFFFFF", // White text
};

export default WithdrawalReport;
