import React, { useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
  RadioGroup,
  Radio,
} from "@mui/joy";
import axios from "axios";
import { toast } from "react-toastify";
import { CssVarsProvider } from "@mui/joy/styles";
import framesxTheme from "../../theme";
import { FormControlLabel } from "@mui/material";
import front1 from "../../assets/IMG-20241124-WA0018.jpg";
import { useAuth } from "../../context/AuthContext";
import Table from "../../components/Table";
import { useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

const DirectMemberList = () => {
  const { user } = useAuth();
  const userId = user?.uid; // Add null-check for `user`
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "members"));
        const membersList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const filteredMembers = membersList.filter(
          (member) => member.createdBy === userId
        );
        setMembers(filteredMembers);
        setLoading(false); // Set loading to false after fetching
      } catch (error) {
        console.error("Error fetching members from Firebase:", error);
        toast.error("Error fetching members from Firebase!");
        setLoading(false); // Ensure loading is set to false on error
      }
    };

    if (userId) {
      fetchMembers();
    } else {
      setLoading(false); // If `userId` is not available
    }
  }, [userId]);

  const columns = [
    { header: "Name", accessorKey: "name" },
    { header: "Email", accessorKey: "email" },
    { header: "Country", accessorKey: "country" },
    { header: "State", accessorKey: "state" },
    { header: "Mobile Number", accessorKey: "mobileNumber" },
  ];

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Box
        sx={{
          padding: 2,
          minHeight: "100vh",
          position: "relative", // Required for positioning children
          overflow: "hidden", // Ensures content doesn't spill outside
        }}
      >
        <img
          src={front1}
          alt="Background"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1, // Places the image behind the content
          }}
        />
        <Typography
          level="h2"
          style={{
            color: "#FFFFFF", // White text color
            fontWeight: "lg", // Font weight set to large
            fontSize: "2rem", // Increased font size
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Adding a subtle shadow
            marginTop: 30,
          }}
          fontWeight="lg"
        >
          List of Direct Members
        </Typography>

        <Typography
          level="body-sm"
          style={{
            color: "#FFFFFF",
            fontSize: "18px",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
          mb={2}
        >
          Join New Member from here
        </Typography>
        {loading ? (
                <Typography style={{ color: "black" }}>Loading...</Typography>
              ) : members.length > 0 ? (
                <Table columns={columns} data={members} />
              ) : (
                <Typography style={{ color: "black" }}>
                  No members found.
                </Typography>
              )}
      </Box>
    </CssVarsProvider>
  );
};

export default DirectMemberList;
