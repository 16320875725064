import React, { useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Sheet,
  Button,
} from "@mui/joy";
import { CssVarsProvider } from "@mui/joy/styles";
import { getAuth, updatePassword } from "firebase/auth"; // Firebase imports
import framesxTheme from "../../theme";
import SupportSystem from "../support/SupportSystem";
import front1 from "../../assets/IMG-20241124-WA0018.jpg";


function ModifiedPassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // State for success message

  const auth = getAuth();
  auth.languageCode = "en"; // Set language preference for Firebase auth messages

  // Function to handle Save button click
  const handleSave = async () => {
    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      setErrorMessage("New Password and Confirm Password do not match.");
      setSuccessMessage("");
      return;
    }

    const user = auth.currentUser;

    // Ensure user is authenticated
    if (user) {
      try {
        await updatePassword(user, newPassword);
        setErrorMessage("");
        setSuccessMessage("Password updated successfully!");
      } catch (error) {
        setErrorMessage(`Failed to update password: ${error.message}`);
        setSuccessMessage("");
      }
    } else {
      setErrorMessage("No authenticated user found. Please log in again.");
      setSuccessMessage("");
    }
  };

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Box
        sx={{
          padding: 2,
          minHeight: "100vh",
          position: "relative", // Required for positioning children
          overflow: "hidden", // Ensures content doesn't spill outside
        }}
      >
        <img
          src={front1}
          alt="Background"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1, // Places the image behind the content
          }}
        />
        <Typography level="h2" style={{ color: "#FFFFFF", marginTop: 30, textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", }} fontWeight="lg">
          My Password
        </Typography>
        <Typography level="body-sm" style={{ color: "#FFFFFF", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }} mb={2}>
          Modify the New Password
        </Typography>
        <Sheet
          variant="soft"
          sx={{
            padding: 3,
            borderRadius: "sm",
            maxWidth: 1500,
            marginTop: 2,
            backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white
            backdropFilter: "blur(10px)", // Optional: Adds a blur effect for the content behind
            boxShadow: "none",
          }}
          bgcolor="neutral.softHoverBg"
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel style={{ color: "black" }}>
                Current Password*
              </FormLabel>
              <Input
                placeholder="Enter Current Password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                type="password"
                sx={{backgroundColor: "#1a2a339c", color: 'white'}}
              />
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel style={{ color: "black" }}>New Password*</FormLabel>
              <Input
                placeholder="Enter New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type="password"
                sx={{backgroundColor: "#1a2a339c", color: 'white'}}
              />
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel style={{ color: "black" }}>
                Confirm Password*
              </FormLabel>
              <Input
                placeholder="Enter Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                sx={{backgroundColor: "#1a2a339c", color: 'white'}}
              />
            </FormControl>
            {errorMessage && (
              <Typography level="body-sm" color="error" mt={1}>
                {errorMessage}
              </Typography>
            )}
            {successMessage && (
              <Typography level="body-sm" color="success" mt={1}>
                {successMessage}
              </Typography>
            )}
            <Button
              variant="solid"
              color="primary"
              sx={{ alignSelf: "center", marginTop: 2 }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Sheet>
      </Box>
      <SupportSystem />
    </CssVarsProvider>
  );
}

export default ModifiedPassword;
