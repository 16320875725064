import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
} from '@mui/joy';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CssVarsProvider } from '@mui/joy/styles';
import framesxTheme from '../../theme';
import * as Yup from 'yup';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import { db } from '../../firebase';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from 'firebase/auth';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SupportSystem from '../support/SupportSystem';
import front1 from '../../assets/IMG-20241124-WA0018.jpg';

// Generate a 6-digit OTP
const generateOTP = () =>
  Math.floor(100000 + Math.random() * 900000).toString();

const AddMember = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    mobileNumber: Yup.string()
      .matches(/^[0-9]{10}$/, 'Invalid mobile number')
      .required('Mobile Number is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
  });
  const { logout, signIn } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ mode: 'onChange' });

  const navigate = useNavigate();
  const [user] = useState(JSON.parse(localStorage.getItem('user')));
  const [responseDialogOpen, setResponseDialogOpen] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [countries, setCountries] = useState([]);
  const [idCards, setIdCards] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    mobileNumber: '',
    email: '',
    country: 'India',
    state: '',
  });
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [prevEmail, setPrevEmail] = useState('');
  const [prevMobile, setPrevMobile] = useState('');
  const [loginEmail, setLoginEmail] = useState(prevEmail);
  const [loginPassword, setLoginPassword] = useState(password);
  const [curentUserData, setCurentUserData] = useState([]);
  const tenantsCollection = collection(db, 'members');

  useEffect(() => {
    const fetchData = async () => {
      if (tenantsCollection) {
        const data = await fetchMembers(tenantsCollection);
        setCurentUserData(data[0]);
      }
    };
    fetchData(); // Call the async function
  }, []);

  async function fetchMembers(ref) {
    const snapshot = query(ref, where('email', '==', user?.email));
    const fetchData = await getDocs(snapshot);
    const results = [];

    fetchData.forEach((doc) => {
      const abc = { id: doc.id, ...doc.data() };
      results.push(abc);
    });

    if (results.length > 0) {
      return results;
    } else {
      return null;
    }
  }

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        'https://restcountries.com/v3.1/all?fields=name,flags'
      );
      setCountries(response.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
      toast.error('Error fetching countries!');
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleResponseDialogClose = () => {
    setResponseDialogOpen(false);
    handleLogout();
  };

  const handleSave = () => {
    const element = document.createElement('a');
    const file = new Blob(
      [
        `User ID: ${userId}\nPassword: ${password}\nEmail: ${prevEmail}\nMobile Number: ${prevMobile}`,
      ],
      { type: 'text/plain' }
    );
    element.href = URL.createObjectURL(file);
    element.download = 'NewUserDetails.txt';
    document.body.appendChild(element);
    element.click();
    handleLogout();
    setLoginDialogOpen(true);
  };

  const generateBVID = async () => {
    try {
      // Fetch all members from Firestore
      const querySnapshot = await getDocs(collection(db, 'members'));

      // Extract existing BVIDs and find the max number
      const bvidNumbers = querySnapshot.docs
        .map((doc) => doc.data().BVID) // Extract BVID from each member
        .filter((bvid) => bvid.startsWith('BV')) // Ensure it starts with "BV"
        .map((bvid) => parseInt(bvid.replace('BV', ''), 10)) // Extract the numeric part
        .filter((num) => !isNaN(num)); // Ensure valid numbers

      const maxBVID = Math.max(0, ...bvidNumbers); // Default to 0 if no BVIDs exist
      const newBVID = `BV${String(maxBVID + 1).padStart(6, '0')}`; // Generate new BVID

      return newBVID;
    } catch (error) {
      console.error('Error generating BVID:', error);
      toast.error('Error generating unique BVID!');
      throw new Error('BVID generation failed');
    }
  };

  const handleSubmits = async (event) => {
    event.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });

      const auth = getAuth(); // Firebase Auth instance
      const generatedPassword = Math.floor(100000 + Math.random() * 900000); // Generate random password
      // Generate a unique BVID
      const bvid = await generateBVID();

      // Create user in Firebase Auth with email and generated password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        generatedPassword
      );

      const userAuth = userCredential.user;

      // Send email verification
      await sendEmailVerification(userAuth);

      toast.info(
        'A verification email has been sent. Please verify your email before proceeding.'
      );

      await signOut(auth);

      // Monitor email verification status
      const intervalId = setInterval(async () => {
        await userAuth.reload();
        if (userAuth.emailVerified) {
          clearInterval(intervalId);

          // Save to Firebase Firestore
          const docRef = await addDoc(collection(db, 'members'), {
            ...formData,
            BVID: bvid,
            parentId: curentUserData.BVID,
            createdBy: user.uid,
            idCardDTOList: idCards,
          });

          // Set the response details
          setResponseMessage('Member added successfully');
          setUserId(userAuth.uid);
          setPassword(generatedPassword);
          setPrevEmail(formData.email);
          setPrevMobile(formData.mobileNumber);
          setResponseDialogOpen(true);

          toast.success('Data saved successfully to Firebase!');

          // Reset form
          setFormData({
            name: '',
            mobileNumber: '',
            email: '',
            country: '',
            state: '',
          });
          setIdCards([]);
          setErrors({});
        }
      }, 3000); // Check email verification status every 3 seconds
    } catch (error) {
      if (error.name === 'ValidationError') {
        const yupErrors = {};
        error.inner.forEach((e) => {
          yupErrors[e.path] = e.message;
        });
        setErrors(yupErrors);
      } else {
        console.error('Error saving data:', error);
        toast.error('Error saving data to Firebase!');
      }
    }
  };

  const onSubmit = async ({ email, password }) => {
    try {
      await signIn(email, password); // Call the signIn function from your auth hook
      setLoginDialogOpen(false); // Close the modal after successful login
    } catch (err) {
      console.log('Login error:', err);
    }
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // if (name === "country") {
    //   const cardsData = await fetchIdCards(value);
    //   setIdCards(cardsData);
    // }

    try {
      await Yup.reach(validationSchema, name).validate(value);
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    } catch (validationError) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationError.message,
      }));
    }
  };

  const handleIdCardChange = (event, idCardId) => {
    const { value } = event.target;
    setIdCards((prevIdCards) =>
      prevIdCards.map((idCard) =>
        idCard.idCardId === idCardId
          ? { ...idCard, idCardNumber: value }
          : idCard
      )
    );
  };

  useEffect(() => {
    validationSchema.isValid(formData).then((valid) => {
      setIsValid(valid);
    });
  }, [formData, idCards]);

  const handleLogout = async () => {
    // await logout().then(() => {
    //   // navigate("/");
    //   setLoginDialogOpen(true);
    // });
    await logout();
  };

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1, // Places the image behind the content
        }}
      />

      <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
        <Box sx={{ padding: 2 }}>
          <div className="px-4">
            <Typography
              level="h2"
              style={{
                color: '#FFFFFF', // White text color
                fontWeight: 'lg', // Font weight set to large
                fontSize: '2rem', // Increased font size
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Adding a subtle shadow
                marginTop: 30,
              }}
            >
              Add New Member
            </Typography>
            <Typography
              level="body-sm"
              style={{
                color: '#FFFFFF',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              }}
              mb={2}
            >
              Join New Member from here
            </Typography>
          </div>
          <Sheet
            variant="soft"
            sx={{
              padding: 3,
              borderRadius: 'sm',
              maxWidth: 1500,
              backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent white
              backdropFilter: 'blur(10px)', // Optional: Adds a blur effect for the content behind
              boxShadow: 'none',
            }}
            bgcolor="neutral.softHoverBg"
          >
            <form onSubmit={handleSubmits}>
              <Typography level="h4" color="text.primary" mb={2}>
                Personal Details
              </Typography>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Name:</FormLabel>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={(e) => {
                      // Convert the input value to uppercase before updating the state
                      handleInputChange({
                        target: {
                          name: 'name',
                          value: e.target.value.toUpperCase(),
                        },
                      });
                    }}
                    required
                    sx={{
                      textTransform: 'uppercase',
                      backgroundColor: '#1a2a339c',
                    }} // Optional, for visual styling
                  />

                  {errors.name && (
                    <Typography color="error" variant="body2">
                      {errors.name}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Mobile Number:</FormLabel>
                  <Input
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleInputChange}
                    required
                    sx={{ backgroundColor: '#1a2a339c' }}
                  />
                  {errors.mobileNumber && (
                    <Typography color="error" variant="body2">
                      {errors.mobileNumber}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Email ID:</FormLabel>
                  <Input
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    sx={{ backgroundColor: '#1a2a339c' }}
                  />
                  {errors.email && (
                    <Typography color="error" variant="body2">
                      {errors.email}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Country:</FormLabel>
                  <select
                    className="px-2 py-2 border rounded bg-transparent border-gray-600"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    required
                    style={{ backgroundColor: '#1a2a339c' }}
                  >
                    {countries &&
                      Object.keys(countries).map((key) => (
                        <option key={key} value={countries[key].name.common}>
                          <img
                            src={countries[key].flags.png}
                            alt={`Flag of ${countries[key].name.common}`}
                            style={{ width: '20px', marginRight: '10px' }}
                          />
                          {countries[key].name.common}
                        </option>
                      ))}
                  </select>
                  {errors.country && (
                    <Typography color="error" variant="body2">
                      {errors.country}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>State:</FormLabel>
                  <Input
                    name="state"
                    value={formData.state}
                    onChange={(e) => {
                      // Convert the input value to uppercase before updating the state
                      handleInputChange({
                        target: {
                          name: 'state',
                          value: e.target.value.toUpperCase(),
                        },
                      });
                    }}
                    required
                    sx={{
                      textTransform: 'uppercase',
                      backgroundColor: '#1a2a339c',
                    }} // Optional, for visual styling
                  />

                  {errors.state && (
                    <Typography color="error" variant="body2">
                      {errors.state}
                    </Typography>
                  )}
                </FormControl>
                {idCards.map((idCard) => (
                  <FormControl key={idCard.idCardId} sx={{ marginBottom: 2 }}>
                    <FormLabel>{idCard.idCardName}:</FormLabel>
                    <Input
                      name={idCard.idCardName.toLowerCase()}
                      value={idCard.idCardNumber || ''}
                      onChange={(event) =>
                        handleIdCardChange(event, idCard.idCardId)
                      }
                      required
                    />
                  </FormControl>
                ))}
              </div>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="submit"
                  color="primary"
                  variant="solid"
                  sx={{ marginTop: 2 }}
                  disabled={!isValid}
                >
                  Create Member
                </Button>
              </Box>
            </form>
          </Sheet>

          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={responseDialogOpen}
            onClose={handleResponseDialogClose}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Sheet
              variant="outlined"
              sx={{
                maxWidth: 700,
                borderRadius: 'md',
                p: 5,
                boxShadow: 'lg',
              }}
            >
              <ModalClose variant="plain" sx={{ m: 0 }} />
              <Typography
                level="body-lg"
                fontWeight="md"
                color="text.secondary"
                mb={2}
              >
                Save the User ID and Password of New User
              </Typography>
              <Typography>
                <b>User ID : </b> {prevEmail}
              </Typography>
              <Typography>
                <b>Password : </b> {password}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                sx={{ marginTop: 2 }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Sheet>
          </Modal>

          <Modal
            open={loginDialogOpen}
            onClose={() => setLoginDialogOpen(false)}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Sheet
              variant="outlined"
              sx={{ maxWidth: 500, borderRadius: 'md', p: 5 }}
            >
              <Typography level="h5" fontWeight="md" mb={2}>
                verify
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Email:</FormLabel>
                  <Input
                    {...register('email', { required: true })}
                    type="email"
                    placeholder="Enter your email"
                  />
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Password:</FormLabel>
                  <Input
                    {...register('password', { required: true })}
                    type="password"
                    placeholder="Enter your password"
                  />
                </FormControl>
                <Button
                  variant="solid"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Logging in...' : 'Login'}
                </Button>
              </form>
            </Sheet>
          </Modal>
          <SupportSystem />
        </Box>
      </CssVarsProvider>
    </div>
  );
};

export default AddMember;
