// import React, { useState, useEffect } from "react";
// import { Box, Typography, Sheet } from "@mui/joy";
// import { collection, getDocs } from "firebase/firestore";
// import { toast } from "react-toastify";
// import { CssVarsProvider } from "@mui/joy/styles";
// import framesxTheme from "../../theme";
// import { useAuth } from "../../context/AuthContext";
// import { db } from "../../firebase";
// import Table from "../../components/Table";
// import front1 from "../../assets/IMG-20241124-WA0018.jpg";

// const MemberList = () => {
//   const { user } = useAuth();
//   const userId = user?.uid; // Add null-check for `user`
//   const [members, setMembers] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchMembers = async () => {
//       try {
//         const querySnapshot = await getDocs(collection(db, "members"));
//         const membersList = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
//         const filteredMembers = membersList.filter(
//           (member) => member.createdBy === userId
//         );
//         setMembers(filteredMembers);
//         setLoading(false); // Set loading to false after fetching
//       } catch (error) {
//         console.error("Error fetching members from Firebase:", error);
//         toast.error("Error fetching members from Firebase!");
//         setLoading(false); // Ensure loading is set to false on error
//       }
//     };

//     if (userId) {
//       fetchMembers();
//     } else {
//       setLoading(false); // If `userId` is not available
//     }
//   }, [userId]);

//   const columns = [
//     { header: "Name", accessorKey: "name" },
//     { header: "Email", accessorKey: "email" },
//     { header: "Country", accessorKey: "country" },
//     { header: "State", accessorKey: "state" },
//     { header: "Mobile Number", accessorKey: "mobileNumber" },
//   ];

//   return (
//     <div style={{ position: "relative", minHeight: "100vh" }}>
//       {/* Background Image */}
//       <img
//         src={front1}
//         alt="Background"
//         style={{
//           width: "100%",
//           height: "100%",
//           objectFit: "cover",
//           position: "absolute",
//           top: 0,
//           left: 0,
//           zIndex: -1, // Places the image behind the content
//         }}
//       />
//       <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
//         <Box sx={{ padding: { xs: 2, sm: 3 } }}>
//           <Typography
//             level="h2"
//             style={{
//               color: "#FFFFFF", // White text color
//               fontWeight: "lg", // Font weight set to large
//               fontSize: "2rem", // Increased font size
//               textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Adding a subtle shadow
//               marginTop: 30,
//             }}
//           >
//             List of Members
//           </Typography>
//           <Sheet
//             variant="soft"
//             sx={{
//               pt: 1,
//               borderRadius: "sm",
//               maxWidth: { xs: "100%", sm: "90%" },
//               marginX: "auto",
//               overflow: "hidden",
//             }}
//           >
//             <Box sx={{ overflowX: "auto" }}>
//               {loading ? (
//                 <Typography style={{ color: "black" }}>Loading...</Typography>
//               ) : members.length > 0 ? (
//                 <Table columns={columns} data={members} />
//               ) : (
//                 <Typography style={{ color: "black" }}>
//                   No members found.
//                 </Typography>
//               )}
//             </Box>
//           </Sheet>
//         </Box>
//       </CssVarsProvider>
//     </div>
//   );
// };

// export default MemberList;


import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../context/AuthContext';
import { useGenealogyTree } from '../../context/TreeContext';
import { Box, CssVarsProvider, Typography } from '@mui/joy';
import framesxTheme from "../../theme";
import front1 from "../../assets/IMG-20241124-WA0018.jpg";
import Table from '../../components/Table';


const MemberList = () => {
  const { user } = useAuth();
  const { treeData, setTreeData } = useGenealogyTree();
  const [loading, setLoading] = useState(true);
  const [curentUserData, setCurentUserData] = useState(null);
  const [flatData, setFlatData] = useState([]);
  const tenantsCollection = collection(db, 'members');
  useEffect(() => {
    const fetchData = async () => {
      if (tenantsCollection) {
        const data = await fetchCurMembers(tenantsCollection);
        if (data) {
          setCurentUserData(data[0]);
        }
      }
    };
    fetchData();
  }, [user]);

  async function fetchCurMembers(ref) {
    const snapshot = query(ref, where('email', '==', user?.email));
    const fetchData = await getDocs(snapshot);
    const results = [];

    fetchData.forEach((doc) => {
      results.push({ id: doc.id, ...doc.data() });
    });

    return results.length > 0 ? results : null;
  }

  useEffect(() => {
    const loadTree = async () => {
      if (!curentUserData || !curentUserData.BVID) return;

      try {
        const members = await fetchMembers();

        const currentUser = members.find(
          (member) => member.BVID === curentUserData.BVID
        );

        if (!currentUser) {
          throw new Error('Current user data not found');
        }

        const tree = {
          name: currentUser.name,
          attributes: { BVID: currentUser.BVID },
          children: buildTree(members, currentUser.BVID),
        };

        // Flatten the tree data
        const flattenedData = flattenTree(tree);
        setFlatData(flattenedData);

        // Store tree data in the context
        setTreeData(tree);
      } catch (error) {
        console.error('Error fetching tree data:', error);
      } finally {
        setLoading(false);
      }
    };
    loadTree();
  }, [curentUserData, setTreeData]);

  const fetchMembers = async () => {
    const querySnapshot = await getDocs(collection(db, 'members'));
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  };

  const buildTree = (members, parentId) => {
    const children = members
      .filter((member) => member.parentId === parentId)
      .map((child) => ({
        name: child.name,
        email: child.email,
        state: child.state,
        mobileNumber: child.mobileNumber,
        country: child.country,
        attributes: { BVID: child.BVID },
        children: buildTree(members, child.BVID) || null,
      }));
    return children.length > 0 ? children : null;
  };

  const flattenTree = (node, level = 0) => {
    const result = [];
    result.push({ name: node.name,email:node.email, country: node.country, state: node.state,mobileNumber: node.mobileNumber,BVID: node.attributes?.BVID, level });

    if (node.children) {
      node.children.forEach((child) => {
        result.push(...flattenTree(child, level + 1));
      });
    }
    return result;
  };

  const columns = [
    { header: "Name", accessorKey: "name" },
    { header: "Email", accessorKey: "email" },
    { header: "Country", accessorKey: "country" },
    { header: "State", accessorKey: "state" },
    { header: "Mobile Number", accessorKey: "mobileNumber" },
  ];

  return (
     <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Box
        sx={{
          padding: 2,
          minHeight: "100vh",
          position: "relative", // Required for positioning children
          overflow: "hidden", // Ensures content doesn't spill outside
        }}
      >
        <img
          src={front1}
          alt="Background"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1, // Places the image behind the content
          }}
        />
        <Typography
          level="h2"
          style={{
            color: "#FFFFFF", // White text color
            fontWeight: "lg", // Font weight set to large
            fontSize: "2rem", // Increased font size
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Adding a subtle shadow
            marginTop: 30,
          }}
          fontWeight="lg"
        >
          List of Direct Members
        </Typography>

        <Typography
          level="body-sm"
          style={{
            color: "#FFFFFF",
            fontSize: "18px",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
          mb={2}
        >
          Join New Member from here
        </Typography>
        {loading ? (
                <Typography style={{ color: "black" }}>Loading...</Typography>
              ) : flatData.length > 0 ? (
                <Table columns={columns} data={flatData.slice(1, flatData.length)} />
              ) : (
                <Typography style={{ color: "black" }}>
                  No members found.
                </Typography>
              )}
      </Box>
    </CssVarsProvider>
  );
};

export default MemberList;
