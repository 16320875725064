import React from "react";
import { Box, Typography, Sheet, Button, Table } from "@mui/joy";
import { CssVarsProvider } from "@mui/joy/styles";
import framesxTheme from "../../theme";
import trc20Image from "../../assets/icons/qrcode.jpg";
import LiveBalanceRequest from "./../liveBalanceRequest";
import front1 from "../../assets/IMG-20241124-WA0018.jpg";
import { toast } from "react-toastify";

const Trcdeposit = () => {
  const handleCopy = () => {
    navigator.clipboard.writeText("TLvkKBCpJBbE2mCrvLs9AqagXFF1Gp6s6F");
    toast.success("Copied to clipboard!");
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1, // Places the image behind the content
        }}
      />
      <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
        <Box sx={{ padding: 2, textAlign: "left" }}>
          <Typography
            level="h2"
            color="text.primary"
            fontWeight="lg"
            // Adding a subtle shadow
            sx={{
              mb: 2,
              marginTop: 7,
              color: "#fff",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)",
            }}
          >
            Please Choose TRC20 network after scan. Otherwise your Payment will
            be cancelled
          </Typography>

          {/* Image Section */}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="img"
              src={trc20Image} // Path to your image
              alt="TRC20 Network Instructions"
              sx={{
                display: "block",
                maxWidth: "100%",
                height: "auto",
                mb: 2,
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
              }}
            />
          </div>
          <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                borderRadius: "md",
                backgroundColor: "#1a2a339c",
                padding: 1,
                width: "100%",
                border: "1px solid #98999a",
                marginLeft: 5
              }}
            >
          <Typography
            level="body1"
            color="text.secondary"
            sx={{ mt: 1, mb: 2 }}
          >
            TLvkKBCpJBbE2mCrvLs9AqagXFF1Gp6s6F
          </Typography>
          <Button
        variant="solid"
        color="primary"
        sx={{ mt: { xs: 2, sm: 0 }, ml: { sm: 2 } }}
        onClick={handleCopy}
      >
        Copy
      </Button>
          </Box>
          {/* <Typography
            level="h2"
            color="text.primary"
            fontWeight="lg"
            sx={{ mb: 2 }}
          >
            ***Please refresh after sending fund and Ckeck your transactions***
          </Typography> */}
        </Box>
        <LiveBalanceRequest />
      </CssVarsProvider>
    </div>
  );
};

export default Trcdeposit;
