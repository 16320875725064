import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import IconButton from '@mui/joy/IconButton';
import Sheet from '@mui/joy/Sheet';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { toggleSidebar } from '../utils';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { updateDoc, doc } from 'firebase/firestore';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../firebase';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box, Card, CardContent, Modal, Typography } from '@mui/joy';

export default function Header() {
  const [messages, setMessages] = useState([]);
  const [unseenMessagesCount, setUnseenMessagesCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const messagesRef = collection(db, 'messages');
        const q = query(messagesRef, orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);

        const fetchedMessages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMessages(fetchedMessages);

        // Calculate unseen messages count
        const unseenCount = fetchedMessages.filter((msg) => !msg.seen).length;
        setUnseenMessagesCount(unseenCount);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, [db]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    markMessagesAsSeen(); // Optionally mark all messages as seen
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const markMessagesAsSeen = async () => {
    const unseenMessages = messages.filter((msg) => !msg.seen);
    try {
      for (const message of unseenMessages) {
        const messageRef = doc(db, 'messages', message.id);
        await updateDoc(messageRef, { seen: true });
      }
      setUnseenMessagesCount(0); // Reset unseen count after marking as seen
    } catch (error) {
      console.error('Error updating message status:', error);
    }
  };
  return (
    <Sheet
      sx={{
        display: { xs: 'flex', md: 'flex', lg: 'flex' },
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'fixed',
        top: 0,
        width: '100vw',
        height: '8%',
        zIndex: 9998,
        p: 2,
        gap: 1,
        // borderBottom: '1px solid',
        // borderColor: 'background.level1',
        // background: { lg: '#0000005e', md: '#0000005e' },
        background: '#ce0000',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.8)',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Header-height': '52px',
            [theme.breakpoints.up('md')]: {
              '--Header-height': '0px',
            },
          },
        })}
      />
      <IconButton
        onClick={() => toggleSidebar()}
        variant="outlined"
        color="neutral"
        size="sm"
      >
        <MenuRoundedIcon />
      </IconButton>
      <div onClick={handleOpenModal} style={{ padding: '2%' }}>
        <NotificationsActiveIcon
          sx={{
            color: '#fff',
            cursor: 'pointer',
          }}
        />
        {unseenMessagesCount > 0 && (
          <span
            style={{
              color: 'rgb(13 0 255 / 96%)',
              marginLeft: '10px',
              cursor: 'pointer',
            }}
          >
            {unseenMessagesCount} New
          </span>
        )}
      </div>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            // border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
          }}
        >
          <Typography level="h6" fontWeight="lg" sx={{ marginBottom: 2 }}>
            Notifications
          </Typography>
          {messages.length === 0 ? (
            <Typography>No messages available.</Typography>
          ) : (
            messages.map((msg) => (
              <Card
                key={msg.id}
                sx={{ marginBottom: 2, backgroundColor: '#f5f5f5' }}
              >
                <CardContent>
                  <Typography sx={{ fontSize: '14px', marginBottom: '4px' }}>
                    {new Date(msg.createdAt?.seconds * 1000).toLocaleString()}
                  </Typography>
                  <Typography level="h6" fontWeight="lg">
                    {msg.title}
                  </Typography>
                  <Typography>{msg.message}</Typography>
                </CardContent>
              </Card>
            ))
          )}
        </Box>
      </Modal>
    </Sheet>
  );
}
