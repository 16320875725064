// import * as React from "react";
// import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";
// import GlobalStyles from "@mui/joy/GlobalStyles";
// import CssBaseline from "@mui/joy/CssBaseline";
// import Box from "@mui/joy/Box";
// import Button from "@mui/joy/Button";
// import Checkbox from "@mui/joy/Checkbox";
// import Divider from "@mui/joy/Divider";
// import FormControl from "@mui/joy/FormControl";
// import FormLabel, { formLabelClasses } from "@mui/joy/FormLabel";
// import IconButton from "@mui/joy/IconButton";
// import Link from "@mui/joy/Link";
// import Input from "@mui/joy/Input";
// import Typography from "@mui/joy/Typography";
// import Stack from "@mui/joy/Stack";
// import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
// import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
// import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
// import GoogleIcon from "../assets/icons/GoogleIcon";
// import { toast } from "react-toastify";
// import { useFormAction, useNavigate } from "react-router-dom";
// import { useAuth } from "../context/AuthContext";
// import { useForm } from 'react-hook-form';

// import bgImgWhite from "../assets/icons/InvestmentWhite.png";
// import bgImgBlack from "../assets/icons/InvestmentBlack.png";

// import logo from "../assets/icons/logo.png";
// import logo_name from "../assets/icons/BV (1).png";

// function ColorSchemeToggle(props) {
//   const { onClick, ...other } = props;
//   const { mode, setMode } = useColorScheme();
//   const [mounted, setMounted] = React.useState(false);
//   React.useEffect(() => {
//     setMounted(true);
//   }, []);
//   if (!mounted) {
//     return <IconButton size="sm" variant="outlined" color="neutral" disabled />;
//   }
//   return (
//     <IconButton
//       id="toggle-mode"
//       size="sm"
//       variant="outlined"
//       color="neutral"
//       aria-label="toggle light/dark mode"
//       {...other}
//       onClick={(event) => {
//         if (mode === "light") {
//           setMode("dark");
//         } else {
//           setMode("light");
//         }
//         onClick?.(event);
//       }}
//     >
//       {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
//     </IconButton>
//   );
// }

// export default function AppSignUp() {
//   const { signIn, user, token } = useAuth();
//   const navigate = useNavigate();

//   const {
//     register,
//     handleSubmit,
//     formState: { isSubmitting, isDirty, isValid, errors },
//   } = useForm({ mode: 'onChange' });

//   const onSubmit = async ({ email, password }) => {
//     try {
//       await signIn(email, password);
//       navigate('/home');
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   // React.useEffect(() => {
//   //   if (user && token) {
//   //     navigate("/home");
//   //   }
//   // });

//   // const handleLogin = async (event) => {
//   //   event.preventDefault();
//   //   const formElements = event.currentTarget.elements;
//   //   const data = {
//   //     userId: formElements.userId.value,
//   //     password: formElements.password.value,
//   //     persistent: formElements.persistent.checked,
//   //   };
//   //   await signIn(data.userId, data.password)
//   //     .then(() => {
//   //       toast.success("Sucessfully Logged in", {
//   //         position: "top-center",
//   //         autoClose: 600,
//   //         hideProgressBar: true,
//   //         closeOnClick: true,
//   //         pauseOnHover: true,
//   //         draggable: true,
//   //         progress: undefined,
//   //         theme: "colored",
//   //       });
//   //       navigate("/home");
//   //     })
//   //     .catch((err) => {
//   //       console.log("err", err);
//   //     });
//   // };

//   return (
//     <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
//       <CssBaseline />
//       <GlobalStyles
//         styles={{
//           ":root": {
//             "--Collapsed-breakpoint": "769px",
//             "--Cover-width": "50vw",
//             "--Form-maxWidth": "800px",
//             "--Transition-duration": "0.4s",
//           },
//         }}
//       />
//       <Box
//         sx={(theme) => ({
//           width:
//             "clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)",
//           transition: "width var(--Transition-duration)",
//           transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
//           position: "relative",
//           zIndex: 1,
//           display: "flex",
//           justifyContent: "flex-end",
//           backdropFilter: "blur(12px)",
//           backgroundColor: "rgba(255 255 255 / 0.2)",
//           [theme.getColorSchemeSelector("dark")]: {
//             backgroundColor: "rgba(19 19 24 / 0.4)",
//           },
//         })}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             minHeight: "100dvh",
//             width:
//               "clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)",
//             maxWidth: "100%",
//             px: 2,
//           }}
//         >
//           <Box
//             component="header"
//             sx={{
//               py: 3,
//               display: "flex",
//               alignItems: "left",
//               justifyContent: "space-between",
//             }}
//           >
//             <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
//               <img className="w-12 me-2" src={logo} />
//               <img className="h-10 me-2" src={logo_name} />
//               {/* <Typography variant="h6">ForexPro</Typography> */}
//             </Box>
//           </Box>
//           <Box
//             component="main"
//             sx={{
//               my: "auto",
//               py: 2,
//               pb: 5,
//               display: "flex",
//               flexDirection: "column",
//               gap: 2,
//               width: 400,
//               maxWidth: "100%",
//               mx: "auto",
//               borderRadius: "sm",
//               "& form": {
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: 2,
//               },
//               [`& .${formLabelClasses.asterisk}`]: {
//                 visibility: "hidden",
//               },
//             }}
//           >
//             {/* <Stack gap={4} sx={{ mb: 2 }}>
//               <Stack gap={1}>
//                 <Typography level="h3">Sign in</Typography>
//                 <Typography level="body-sm">
//                   New User ?{" "}
//                   <Link href="#replace-with-a-link" level="title-sm">
//                     Sign up!
//                   </Link>
//                 </Typography>
//               </Stack>
//               <Button
//                 variant="outlined"
//                 color="neutral"
//                 fullWidth
//                 startDecorator={<GoogleIcon />}
//               >
//                 Continue with Google
//               </Button>
//             </Stack> */}
//             {/* <Divider
//               sx={(theme) => ({
//                 [theme.getColorSchemeSelector("light")]: {
//                   color: { xs: "#FFF", md: "text.tertiary" },
//                   "--Divider-lineColor": {
//                     xs: "#FFF",
//                     md: "var(--joy-palette-divider)",
//                   },
//                 },
//               })}
//             >
//               or
//             </Divider> */}
//             <Stack gap={4} sx={{ mt: 2 }}>
//               <form onSubmit={handleSubmit(onSubmit)}>
//                 <FormControl >
//                   <FormLabel>User ID</FormLabel>
//                   <Input {...register('email')} type="email" name="email" />
//                 </FormControl>
//                 <FormControl >
//                   <FormLabel>Password</FormLabel>
//                   <Input
//                     {...register('password')}
//                     type="password"
//                     name="password"
//                   />
//                 </FormControl>
//                 <Stack gap={4} sx={{ mt: 2 }}>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Checkbox size="sm" label="Remember me" name="persistent" />
//                     <Link level="title-sm" href="#replace-with-a-link">
//                       Forgot your password?
//                     </Link>
//                   </Box>
//                   <Button type="submit" fullWidth>
//                     Sign in
//                   </Button>
//                 </Stack>
//               </form>
//             </Stack>
//           </Box>
//           <Box component="footer" sx={{ py: 3 }}>
//             <Typography level="body-xs" textAlign="center">
//               © Microace Software {new Date().getFullYear()}
//             </Typography>
//           </Box>
//         </Box>
//       </Box>
//       <Box
//         sx={(theme) => ({
//           height: "100%",
//           position: "fixed",
//           right: 0,
//           top: 0,
//           bottom: 0,
//           left: "clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))",
//           transition:
//             "background-image var(--Transition-duration), left var(--Transition-duration) !important",
//           transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
//           backgroundColor: "background.level1",
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           backgroundRepeat: "no-repeat",
//           backgroundImage: `url(${bgImgWhite})`,
//           [theme.getColorSchemeSelector("dark")]: {
//             backgroundImage: `url(${bgImgBlack})`,
//             // 'url(https://www.forextime.com/s3-static/Beginners-Guide/what_is_forex.jpg)',
//           },
//         })}
//       />
//     </CssVarsProvider>
//   );
// }

import * as React from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import IconButton from '@mui/joy/IconButton';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useForm } from 'react-hook-form';
import bgImgWhite from '../assets/icons/new_img.jpg';
import bgImgBlack from '../assets/icons/new_img.jpg';
import logo from '../assets/icons/logo.png';
import logo_name from '../assets/Bhead.png';
import { useState } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { addDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

function ColorSchemeToggle(props) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="neutral" disabled />;
  }
  return (
    <IconButton
      size="sm"
      variant="outlined"
      color="neutral"
      aria-label="toggle light/dark mode"
      {...other}
      onClick={(event) => {
        setMode(mode === 'light' ? 'dark' : 'light');
        onClick?.(event);
      }}
    >
      {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

export default function AppLogin() {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    mobileNumber: Yup.string()
      .matches(/^[0-9]{10}$/, 'Invalid mobile number')
      .required('Mobile Number is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
  });
  const { signIn, user, token } = useAuth();
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [responseDialogOpen, setResponseDialogOpen] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [prevEmail, setPrevEmail] = useState('');
  const [prevMobile, setPrevMobile] = useState('');
  const [userId, setUserId] = useState('');

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ mode: 'onChange' });
  const [referralId, setReferralId] = React.useState('');
  const [referparentId, setReferParentId] = React.useState('');
  const [formData, setFormData] = useState({
    name: '',
    mobileNumber: '',
    email: '',
    country: '',
    state: '',
  });

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refId = urlParams.get('ref');
    const parentId = urlParams.get('parentid');
    if ((refId, parentId)) {
      setReferralId(refId);
      setReferParentId(parentId);
    }
  }, []);

  const fetchCountries = async () => {
    try {
      // const response = await axios.get(
      //   "http://ec2-13-201-97-103.ap-south-1.compute.amazonaws.com:8082/v1/user/country"
      // );
      const response = await axios.get(
        'https://restcountries.com/v3.1/all?fields=name,flags'
      );
      setCountries(response.data);
      // if (response.data.length > 0) {
      //   const cardsData = await fetchIdCards(response.data[0]?.countryName);
      //   setIdCards(cardsData);
      //   setFormData({ ...formData, country: "INDIA" });
      // }
    } catch (error) {
      console.error('Error fetching countries:', error);
      toast.error('Error fetching countries!');
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const generateBVID = async () => {
    try {
      // Fetch all members from Firestore
      const querySnapshot = await getDocs(collection(db, 'members'));

      // Extract existing BVIDs and find the max number
      const bvidNumbers = querySnapshot.docs
        .map((doc) => doc.data().BVID) // Extract BVID from each member
        .filter((bvid) => bvid.startsWith('BV')) // Ensure it starts with "BV"
        .map((bvid) => parseInt(bvid.replace('BV', ''), 10)) // Extract the numeric part
        .filter((num) => !isNaN(num)); // Ensure valid numbers

      const maxBVID = Math.max(0, ...bvidNumbers); // Default to 0 if no BVIDs exist
      const newBVID = `BV${String(maxBVID + 1).padStart(4, '0')}`; // Generate new BVID

      return newBVID;
    } catch (error) {
      console.error('Error generating BVID:', error);
      toast.error('Error generating unique BVID!');
      throw new Error('BVID generation failed');
    }
  };

  const handleSubmits = async (event) => {
    event.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });

      const auth = getAuth(); // Firebase Auth instance

      // Generate a unique BVID
      const bvid = await generateBVID();

      // Create user in Firebase Auth with email and generated password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        password
      );

      const userAuth = userCredential.user;

      // Send email verification
      await sendEmailVerification(userAuth);

      toast.info(
        'A verification email has been sent. Please verify your email before proceeding.'
      );

      // Monitor email verification status
      const intervalId = setInterval(async () => {
        await userAuth.reload();
        if (userAuth.emailVerified) {
          clearInterval(intervalId);

          // Save to Firebase Firestore
          const docRef = await addDoc(collection(db, 'members'), {
            ...formData,
            BVID: bvid,
            parentId: referparentId,
            createdBy: referralId,
          });

          // Set the response details
          setResponseMessage('successfull');
          setUserId(userAuth.uid);
          setPassword(password);
          setPrevEmail(formData.email);
          setPrevMobile(formData.mobileNumber);
          setResponseDialogOpen(true);

          toast.success('Data saved successfully to Firebase!');

          // Reset form
          setFormData({
            name: '',
            mobileNumber: '',
            email: '',
            country: '',
            state: '',
          });
          setErrors({});
        }
      }, 3000); // Check email verification status every 3 seconds
    } catch (error) {
      if (error.name === 'ValidationError') {
        const yupErrors = {};
        error.inner.forEach((e) => {
          yupErrors[e.path] = e.message;
        });
        setErrors(yupErrors);
      } else {
        console.error('Error saving data:', error);
        toast.error('Error saving data to Firebase!');
      }
    }
  };

  const onSubmit = async ({ email, password }) => {
    try {
      await signIn(email, password);
    } catch (err) {
      console.log('Login error:', err);
    }
  };

  // Watch for user and token changes to navigate after successful login
  React.useEffect(() => {
    if (user && token) {
      navigate('/home');
    }
  }, [user, token, navigate]);

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // if (name === "country") {
    //   const cardsData = await fetchIdCards(value);
    //   setIdCards(cardsData);
    // }

    try {
      await Yup.reach(validationSchema, name).validate(value);
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    } catch (validationError) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationError.message,
      }));
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px',
            '--Cover-width': '50vw',
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s',
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: '#0A221F',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <img className="w-12 me-2" src={logo} alt="Logo" />
              <img className="h-10 me-2" src={logo_name} alt="Logo Name" />
            </Box>
          </Box>
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: 'hidden',
              },
            }}
          >
            <form onSubmit={handleSubmits}>
              <FormControl>
                <FormLabel style={{ color: 'white' }}>Reference ID</FormLabel>
                <Input type="text" value={referralId} readOnly />
              </FormControl>
              <FormControl>
                <FormLabel style={{ color: 'white' }}>Name</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl>
                <FormLabel style={{ color: 'white' }}>Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl>
                <FormLabel style={{ color: 'white' }}>Mobile Number</FormLabel>
                <Input
                  type="text"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl>
                <FormLabel style={{ color: 'white' }}>Country</FormLabel>
                <select
                  className="px-2 py-2 border rounded bg-transparent border-gray-600 bg-white"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                >
                  {countries &&
                    Object.keys(countries).map((key) => (
                      <option key={key} value={countries[key].name.common}>
                        <img
                          src={countries[key].flags.png}
                          alt={`Flag of ${countries[key].name.common}`}
                          style={{ width: '20px', marginRight: '10px' }}
                        />
                        {countries[key].name.common}
                      </option>
                    ))}
                </select>
              </FormControl>
              <FormControl>
                <FormLabel style={{ color: 'white' }}>State</FormLabel>
                <Input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>

              <FormControl>
                <FormLabel style={{ color: 'white' }}>Password</FormLabel>
                <Input
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
              </FormControl>
              <Stack gap={4} sx={{ mt: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Link href="#replace-with-a-link" sx={{ marginLeft: 'auto' }}>
                    Forgot your password?
                  </Link>
                </Box>

                <Button
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                  style={{ color: 'white' }}
                >
                  Sign Up
                </Button>
              </Stack>
            </form>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © Microace Software {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${bgImgWhite})`,
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage: `url(${bgImgBlack})`,
          },
        })}
      />
    </CssVarsProvider>
  );
}
